import React, { useEffect, useState } from "react"
import axios from "axios"
import url from "../../config"
import toast from "react-hot-toast"
import {useNavigate} from "react-router-dom" 
import { useSelector } from "react-redux"

const PostProduct=()=>{
    const user=useSelector((state)=> state.user.user
    )
    const history=useNavigate()
  useEffect(()=>{ 
    if(!user||user.role!=="Admin") history("/")
  },[])  
const [input,setinput]=useState({
    name:"",
    description:"",
    price:"",
    quantity:"",
    promotion:"",
    category:"",
    subcategory:"",
    conseils:"",
    imageUrl:""
}
)

    const handelchange=(e)=>{
       
setinput((prev)=>({
    ...prev,
    [e.target.name]:e.target.value

}))
    }

const handelclick=async(e)=>{
    e.preventDefault();
    try {
        const res=await axios.post(`${url}/product`,{
            name:input.name,
            description:input.description,
            price:Number(input.price),
            quantity:Number(input.quantity),
            promotion:Number(input.promotion),
            category:input.category,
            subcategory:input.subcategory,
            imageUrl:input.imageUrl,
            conseils:input.conseils
        },{withCredentials:true})
        toast.success(res.data.message)
        window.scrollTo(0,0)
                
    } catch (error) {
        toast.error(error.response.data.message)
    }

}

    return(
        <form className=" grid rounded-lg grid-cols-1 mt-5 w-[80%] h-auto mx-auto border-[6px] border-purple-800" onSubmit={handelclick}>
<label className="w-[50%] mx-auto">name :</label>
<input className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2" type="text"  name="name" onChange={handelchange} ></input>
<label className="w-[50%] mx-auto">description :</label>
<input type="text" className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"  name="description" onChange={handelchange} ></input>
<label className="w-[50%] mx-auto">conseils d'utilisation :</label>
<input type="text" className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"  name="conseils" onChange={handelchange} ></input>
<label className="w-[50%] mx-auto">price :</label>
<input type="number" className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2" name="price" onChange={handelchange} ></input>
<label className="w-[50%] mx-auto">quantity :</label>
<input type="number" className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"  name="quantity" onChange={handelchange} ></input>
<label className="w-[50%] mx-auto">promotion :</label>
<input type="number" className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"  name="promotion" onChange={handelchange} ></input>
<label className="w-[50%] mx-auto">category :</label>
<input type="text" className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"  name="category" onChange={handelchange} ></input>
<label className="w-[50%] mx-auto">subcategory :</label>
<input type="text" className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"  name="subcategory" onChange={handelchange} ></input>
<label className="w-[50%] mx-auto">image :</label>
<input type="text" className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"  name="imageUrl" onChange={handelchange} ></input>

            
            <button className="my-8 w-[20%] max-md:w-[40%] mx-auto border-4 h-9 text-white bg-black border-cyan-200 hover:bg-green-500" type="submit" >ajouter</button>
        </form>

        
    )

}
export default PostProduct