import axios from "axios"
import toast from "react-hot-toast"
import url from "../../config"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setUser } from "../../store/user"



const Login=()=>{

useEffect(()=>{
window.scroll(0,0)
},[])
    const [input,setinput]=useState({
        
        email:"",
        password:"",
        role:"",
        
    }
    )
    
        const handelchange=(e)=>{
    setinput((prev)=>({
        ...prev,
        [e.target.name]:e.target.value
    
    }))
        }
    
        const history=useNavigate()
        const dispatch=useDispatch()
    const handelclick=async(e)=>{
        e.preventDefault()
        
        try {
            const res=await axios.post(`${url}/user/login`,{
               
                "email":input.email,
                
                "password":input.password,
                "role":input.role,
            },{withCredentials:true})
            dispatch(setUser(res.data.user))
            toast.success(res.data.message)
                    history("/")
        } catch (error) {
            toast.error(error.response.data.message)
        }
    
    }
    

    return(
        <form className=" grid rounded-lg grid-cols-1 mt-5  h-auto mx-auto border-[6px] border-gray-400" onSubmit={handelclick}>
      <p className=" text-blue-500 mx-auto w-[50%] my-10 text-2xl">Login to your account : </p>
        <label className="w-[50%] mx-auto">role :</label>
        <select className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2" type="text"  name="role" onChange={handelchange} >
          <option value={""}>select role</option>
            <option value={"Admin"}>Admin</option>
            <option value={"Client"}>Client</option>
        </select>
       
        <label className="w-[50%] mx-auto">email :</label>
        <input type="email" className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2" name="email" onChange={handelchange} ></input>
       
       
        
        <label className="w-[50%] mx-auto">password :</label>
        <input type="password" className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"  name="password" onChange={handelchange} ></input>
       
                    
                    <button className="my-8 w-[20%] max-md:w-[40%] mx-auto border-4 h-9 text-white bg-black border-cyan-200 hover:bg-green-500" type="submit" >Login</button>
                </form>
        
    )
}
export default Login