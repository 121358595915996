
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {useNavigate} from 'react-router-dom'
import { cartActions } from "../../store/cart"
import toast from "react-hot-toast"
import gsap from "gsap"


const Cart=()=>{
  const history= useNavigate()
    const items=useSelector((state)=>
    state.cart.itemsList
)

useEffect(()=>{
  window.scrollTo(0, 0);
  gsap.fromTo(".cart-product",{ x:"-400%" },{x:0, duration:1.5})
},[items])
const dispatch=useDispatch()

const increaseQuantity = (product) => {

  dispatch(cartActions.addToCart(product))
 
};

const decreaseQuantity = (productId) => {
  
  dispatch(cartActions.removeFromCart(productId))
  
};


const handelValid=()=>{
if(items.length>0){
  history("/checkout")
}
else{
  toast.success("votre panier est vide")
}
}

const handeldelete=(idd)=>{
  
  const exitstingItem = items.find((item) => item.id === idd)
  if(exitstingItem){

  dispatch(cartActions.delete(idd))
}
}

    const poursuivre=()=>{
history('/')
    }
let total=0
    for(let i=0;i<items.length;i++){
     
 total=total+items[i].totalPrice
    }
 
return(
    <div>
       <div className=" flex flex-row justify-between my-4"> <p className=" font-bold ml-4">product</p>
        <p className=" font-bold max-sm:hidden ">prix</p>
        <p className=" font-bold max-sm:mr-10  ">quantite</p>
        <p className=" font-bold max-sm:hidden  mr-4">sous-Total</p></div>
        <hr className=" border-2"></hr>

{items && items.length>0?
    items.map((item)=>(
       <div className=" cart-product"> 
<div className=" flex mt-6 flex-row  justify-between">
    <div><button onClick={()=>handeldelete(item.id)} className=" relative ml-2 top-[10px] w-6 pb-7 h-6 border rounded-full text-center text-red-600 border-red-600 hover:opacity-100 opacity-50 font-bold  ">x</button>
<img className=" w-20 h-20 relative top-[-49px] left-8 " src={item.image} alt=""></img> 
<p className=" relative top-[-49px] w-40 ml-4 font-bold">{item.name}  </p>
<p className=" hidden max-sm:block text-green-400  ml-4  max-sm:relative max-sm:top-[-30px]  font-bold mr-48">{item.price} DA</p>
</div>
<p className=" max-sm:hidden  font-bold mr-48">{item.price} DA</p>
<div className="flex items-center relative  max-sm:left-[-40px]  max-sm:mt-4 max-sm:mr-0 top-[-60px] max-sm:top-[-80px] left-[-80px]">
      <button
        className="bg-green-400 text-gray-700 font-bold px-2 py-1 rounded-l"
        onClick={() => decreaseQuantity(item.id)}
      >
        -
      </button>
      <input
        type="text"
        value={item.quantity}
        className="appearance-none w-10 border text-center border-gray-200 px-2 py-1"
        readOnly
      />
      <button
        className="bg-green-400 text-gray-700 px-2 py-1 font-bold rounded-r"
        onClick={() => increaseQuantity(item)}
      >
        +
      </button>
    </div>
<p className=" max-sm:hidden  mr-4 font-bold">{item.totalPrice} DA</p></div>
<hr className=" border-4" ></hr>
</div>))
:(
<div className=" border-8 h-14 my-10 font-bold relative w-full flex items-center justify-center">
votre panier est actuellement vide


</div>
)

}
<button onClick={poursuivre} className=" font-bold  relative my-14 left-[37%] max-md:left-[5%]  w-48 border-4 border-black hover:bg-blue-200 h-10  bg-red-200 rounded-md mx-8 text-center">Pour Suivre les Achats</button>


<div>
  <p className=" font-bold ml-8 my-8 text-xl border-b-4 border-orange-600 w-32 pb-2">panier total : </p>
  
  <hr className=" border-4"></hr>
  <p className=" font-bold ml-8 my-8 ">sous-total :</p> <p className=" font-bold relative top-[-53px] sm:left-[90%] left-[70%]"> {total} DA</p>
  <hr className="  border-4"></hr>
  <p className=" font-bold ml-8 my-8 ">Expédition : </p>
  <hr className=" border-4"></hr>

<button onClick={ handelValid} className=" bg-blue-700 hover:bg-blue-900 mt-10 relative left-[40%] max-sm:left-[5%] text-white font-bold text-center h-10 w-60">VALIDER LA COMMANDE</button>
</div>
    </div>
)

}

export default Cart