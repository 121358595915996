import {createSlice} from "@reduxjs/toolkit"

const rechenchSlice=createSlice({
    name:"recherch",
    initialState:{
        recherche:null,
    },
reducers:{
    setRecherche:(state,action)=>{
        state.recherche=action.payload
    }
}

})
 export const {setRecherche}=
 rechenchSlice.actions;
 export default rechenchSlice.reducer
