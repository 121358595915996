import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from "axios"
import url from '../../config';
import toast from 'react-hot-toast';
//import { placeOrder } from '../redux/actions/orderActions';
import { cartActions } from "../../store/cart"
import { useNavigate } from 'react-router-dom';
const Checkout = () => {
const dispatch=useDispatch()
const history=useNavigate()
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
 // const history = useHistory();
  const cartItems = useSelector((state) => state.cart.itemsList);
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const totalPrice = cartItems.reduce((acc, item) => acc + item.totalPrice, 0);

  const handlePlaceOrder = async() => {
  
    try {
       const orderData = {
      address,
      name,
      email,
      phone,
      items: cartItems.map((item) => ({
        product: item.name,
        quantity: item.quantity,
        sousTotal:item.totalPrice
      })),
    };
    const res=await axios.post(`${url}/sendemail`,orderData
    )

    toast.success(res.data.message)
    
    dispatch(cartActions.reset())
    history("/")
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
   


   
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Checkout</h2>
      <div className="mb-4">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Full Name:
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email:
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
          Phone:
        </label>
        <input
          type="tel"
          id="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="address" className="block text-sm font-medium text-gray-700">
          Shipping Address:
        </label>
        <input
          type="text"
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Order Summary</h3>
        {cartItems.map((item) => (
          <div key={item.product} className="flex justify-between mb-2">
            <p>{item.name} x {item.quantity}</p>
            <p>{item.totalPrice.toFixed(2)} ,DA</p>
          </div>
        ))}
        <hr className="border-t border-gray-300 my-2" />
        <p className="text-lg font-bold">Total: {totalPrice.toFixed(2)}DA</p>
      </div>
      <button
        onClick={handlePlaceOrder}
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
      >
        Place Order
      </button>
    </div>
  );
};

export default Checkout;
