import gsap from "gsap";
import { useEffect } from "react";


const Contact = () => {
  
useEffect(()=>{
  gsap.fromTo(".contact",{x:2000},{scrollTrigger:".contact", x:0,duration:2 ,ease:"power2"})
  gsap.fromTo(".phone",{x:2000},{scrollTrigger:".phone",delay:.4, x:0,duration:2 ,ease:"power2"})
  gsap.fromTo(".email",{x:2000},{scrollTrigger:".email",delay:.8, x:0,duration:2 ,ease:"power2"})
  gsap.fromTo(".address",{x:2000},{scrollTrigger:".address",delay:1.2, x:0,duration:2 ,ease:"power2"})
  
},[])
  return (
    <div>
      <div className=" contact text-2xl my-6 font-serif">
        Contact 
      </div>
      <hr className="  border-4"></hr>
      
      <div className="phone font-bold my-6">Phone/WhatsApp : 0770918413</div>
      <div className="email font-bold my-6">Email : parapharmmimosai@gmail.com</div>
      <div className="address font-bold my-6">Address :</div>
      <div className='w-full mb-10' >
     <iframe title="if" style={{width:"100%",height:"500px"  }} id="gmap_canvas" src="https://maps.google.com/maps?q=parapharm+mimosai%2Coran&t=&z=16&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    </div>
    </div>
  );
}

export default Contact;
