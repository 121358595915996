import React, { Suspense, useEffect, useState } from "react";
import axios from "axios";
import url from "../../config";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UpdateCategory = () => {
  const [category, setCategory] = useState([]);
  const user=useSelector((state)=> state.user.user
  )
  const history=useNavigate()
useEffect(()=>{ 
  if(!user||user.role!=="Admin") history("/")
},[]) 

  useEffect(() => {
    const fetch = async () => {
      window.scrollTo(0,0);
      const res = await axios.get(`${url}/allcategories`);
      setCategory(res.data.category);
    };
    fetch();
  }, []);

  const handelchange = (id, field, value) => {
    setCategory((prev) =>
      prev.map((cat) =>
        cat._id === id ? { ...cat, [field]: value } : cat
      )
    );
  };

  const handleClick = async (id,e) => {
    e.preventDefault()
    try {
      const update = category.find((cat) => cat._id === id);
      
      const res = await axios.patch(
        `${url}/category/${id}`,
        update,
        { withCredentials: true }
      );
      toast.success(res.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

const [editingMode,seteditingMode]=useState(null)

  const handleDelete = async (id,e) => {
    e.preventDefault()
    try {
      
      const res = await axios.delete(
        `${url}/category/${id}`,
        { withCredentials: true }
      );
      setCategory((cats) => cats.filter((cat) => cat._id !== id));
      toast.success(res.data.message);
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message);
    }
  };

  const [input,setinput]=useState("")
const handelsubmit=(e)=>{
e.preventDefault();
const neww =category.filter(pro => pro.name.toLowerCase().includes(input.toLowerCase()));

setCategory(neww)
window.scroll(0,0)
setinput("")
}
  return (
    <>
    <form className=" w-60 h-40 border-8 my-4 mx-auto" onSubmit={handelsubmit}> <label className="ml-2">name of category you search :</label>  <input placeholder="enter the name" className=" pl-2 hover:border-black my-6 mx-4 border-4 border-blue-400 relative w-[80%]" value={input} type="text" onChange={(e)=>{setinput(e.target.value)}}></input>
    <button className=" border-4 border-blue-400 w-20 relative bottom-[0px] ml-16 bg-blue-400 text-white hover:bg-black" type="submit" onClick={handelsubmit}>search</button></form>
   
      {category &&
        category.map((cat) => (
          <Suspense fallback={<p>Loading...</p>}>  <form
            className="grid rounded-lg grid-cols-1 mt-5 w-[80%] h-auto mx-auto border-[6px] border-purple-800"
            key={cat._id}
          >
              <label className="w-[50%] mx-auto">name :</label>
        <input className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2" disabled={
                                  editingMode !== cat._id ? true : false
                                }  type="text" value={cat.name} name="name" onChange={(e)=>handelchange(cat._id,e.target.name,e.target.value)} ></input>
        
        <label className="w-[50%] mx-auto">parent Category :</label>
        <input type="text" disabled={
                                  editingMode !== cat._id ? true : false
                                }  className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2" value={cat.parentCategory}  name="parentCategory" onChange={(e)=>handelchange(cat._id,e.target.name,e.target.value)} ></input>
      
       
                    

            <button
              className="my-8 w-[20%] max-md:w-[40%] mx-auto border-4 h-9 text-white bg-black border-cyan-200 hover:bg-green-500"
              onClick={(e) => handleClick(cat._id,e)}
            >
              Update
            </button>
            <button
              className="my-8 w-[20%] max-md:w-[40%] mx-auto border-4 h-9 text-white bg-red-700 border-cyan-200 hover:bg-red-900"
              onClick={(e) => handleDelete(cat._id,e)}
            >
              Delete
            </button>
            <label className="w-[50%] mx-auto mb-0">enable editing :</label>
            <input type="checkbox" className="  w-4 mt-[-15px] mb-2  border-cyan-400 max-md:ml-[70%]  ml-[37%] "     onChange={()=>{ seteditingMode(cat._id)}}></input>
          </form> </Suspense>
        ))}
      <hr className="w-full"></hr>
    </>
  );
};

export default UpdateCategory;