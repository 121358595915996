import { createSlice } from "@reduxjs/toolkit";


const cartSlice=createSlice({
    name:"cart",
    initialState:{
        itemsList:[],
        totalQuantity:0
    },
    reducers:{
        addToCart:(state,action)=>{
            const newItem=action.payload
            const exist=state.itemsList.find((item=>item.id===newItem._id||item.id===newItem.id))
            if(exist){
                exist.quantity++;
                exist.totalPrice+=newItem.promotion?newItem.promotion:newItem.price
            }
            else{
            state.itemsList.push({
                id: newItem._id,
                price: newItem.promotion?newItem.promotion:newItem.price,
                quantity: 1,
                totalPrice: newItem.promotion?newItem.promotion:newItem.price,
                name: newItem.name,
                image: newItem.imageUrl,
              })
              state.totalQuantity++
            }
        },
        removeFromCart(state, action) {
            const id = action.payload
            const exitstingItem = state.itemsList.find((item) => item.id === id)
            if (exitstingItem.quantity === 1) {
              state.itemsList = state.itemsList.filter((item) => item.id !== id)
              state.totalQuantity--
            } else {
              exitstingItem.quantity--
              exitstingItem.totalPrice -= exitstingItem.price
            }
          },
          delete(state,action){
            const id = action.payload
           
            state.itemsList = state.itemsList.filter((item) => item.id !== id)
            state.totalQuantity--
          },
          reset: (state) => {
            state.itemsList = [];
            state.totalQuantity = 0;
          },
    }}
)

export const cartActions=
 cartSlice.actions;
 export default cartSlice.reducer