import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user";
import categoryReducer from "./category";
import cartReducer from "./cart";
import rechercheReducer from "./recherch";

const persistConfig = {
    key: "root",
    storage: storage,
    
  };

  const persistedUserReducer = persistReducer(persistConfig, userReducer);
const persistedCategoryReducer = persistReducer(persistConfig, categoryReducer);
const persistedCartReducer = persistReducer(persistConfig, cartReducer);
const persistedRechercheReducer = persistReducer(persistConfig, rechercheReducer);


const store=configureStore({
    reducer:{
        user: persistedUserReducer,
        category: persistedCategoryReducer,
        cart: persistedCartReducer,
        recherche: persistedRechercheReducer,
    }
})
const persistor = persistStore(store);
export  { store,persistor };
