import { useEffect, useState } from "react"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import gsap from "gsap"

import { setCategory } from "../../store/category"
import {Link, useNavigate} from "react-router-dom"
import axios from "axios"
import { useGSAP } from "@gsap/react";
import url from "../../config"

 import { setRecherche } from "../../store/recherch"

const Header=()=>{
  const history=useNavigate()
  const dispatch=useDispatch();
 

  useGSAP(()=>{
    //gsap.from(".container",{y:"-100%",duration:.8} )
   
    gsap.fromTo(".li",{opacity:0},{opacity:1,stagger:.3})
    
  });
  
  const [category,setcat]=useState([])
  const totalQuantity=useSelector((state)=>
  state.cart.totalQuantity
)
useEffect(() => {
    // Applying GSAP animation to the list items that are rendered conditionally
    gsap.fromTo(".conditional-li", { opacity: 0 }, {delay:0.6, opacity: 1, stagger: 0.3 });
  }, [category]);
  useEffect(()=>{
const fetch=async()=>{
try {
   const response = await axios.get(`${url}/category`);
  setcat(response.data.category);
} catch (error) {
  console.log(error)
}
 
 
}
fetch()}
  ,[])
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if(menuOpen){
    
    gsap.fromTo(".li",{opacity:0},{opacity:1,stagger:.3})
   
  }};
  const [input,setinput]=useState("")
const handelchange=(e)=>{
setinput(e.target.value)

}
const handleclick=async(e)=>{
e.preventDefault()
if(input){
  dispatch(setRecherche(input))
  const inputElement = document.getElementById("input");
  if (inputElement) {
    inputElement.value = "";
  }
history("/recherche")

}}
 
const user=useSelector((state)=>
state.user.user
)
 const handelbusket=()=>{
setactive("")
 history('/cart')


 }

 


const [active,setactive]=useState("")
    return(
        <header  className="  w-full">
          <div className=" bg-black font-bold text-center h-20 text-white p-4 ">
            BIENVENUE CHEZ MIMOSAI PARAPHARMACIE EN LIGNE
          </div>
  <div className="  container mx-auto flex flex-row  justify-between items-center w-full  h-[100px] text-black p-4">
    <img src="/mimosai.jpg" className=" logo w-20 h-20 rounded-full  "  alt=""></img>
    <img src={`${!menuOpen?'/menu.svg':'/close.png'}`} alt="" className=" text-black font-bold menu-icon z-10 focus:border w-5 cursor-pointer absolute hidden max-md:block max-md:left-[87%] max-sm:left-[90%] max-lg:left-[90%]   " onClick={toggleMenu}>
      </img >
    
    <form onSubmit={handleclick}> <input id="input" className=" input absolute  top-[114px] left-[28%]  w-54  h-8 pl-3 pb-1 border border-gray-300  rounded-md focus:outline-none focus:border-blue-500" type="text" onChange={handelchange}  placeholder=" recherche de produit"/> </form> <svg className=" h-8 cursor-pointer svg bg-cyan-600 hover:bg-fuchsia-700 absolute lg:left-[45%] max-sm:left-[76%] max-lg:left-[52%]"
        xmlns="http://www.w3.org/2000/svg"
        onClick={ handleclick}
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle  cx="11" cy="11" r="8" />
        <line x1="21" y1="21" x2="16.65" y2="16.65" />
      </svg>
   <p className= "  max-md:hidden relative right-[-15%] cursor-pointer  hover:font-bold ml-2">contact me : 0770918413</p> 
   <div className=" basket relative   w-12 h-12 ">
  <img onClick={handelbusket}  className="   w-full h-full max-md:w-14 max-md:h-14 relative  max-md:fixed  max-md:top-[90%] max-md:z-10 max-md:right-[5%]  cursor-pointer    " src="https://cdn-icons-png.flaticon.com/512/2438/2438157.png" alt=""></img>
   <p onClick={handelbusket} className="pp absolute max-md:fixed max-md:top-[90%] max-md:z-10 max-md:right-[5%] top-0 right-8 bg-red-500  text-white rounded-full p-1 text-xs">{totalQuantity}</p>
   </div>
   </div>


   <hr className="border-b-8 border-black"></hr>

{/* in the md    */}
   <div className={`  header  max-md:hidden flex brown text-white h-[100px]`} >

        
    
      <ul className=" flex flex-row   w-[25%]">
        <li data-name ="home" onClick={(e) => {setactive(e.currentTarget.dataset.name)
         setMenuOpen(false)}} className={` li pt-8  mx-4  h-16  px-0  hover:text-red-200 hover:border-b-4 hover: border-b-red-200 focus:outline-none focus:ring focus:border-blue-300 transition duration-300 ease-in-out ${
          active === 'home' ? 'text-red-200 border-b-4 border-b-red-200' : ``
        }`}
  >
<Link to={"/"}>Home</Link>
        </li>
        <li className={` li  pt-8 mx-4  h-16  px-4  hover:text-red-200 hover:border-b-4 hover: border-b-red-200 focus:ring focus:border-blue-300 transition duration-300 ease-in-out ${
          active === 'contact' ? 'text-red-200 border-b-4 border-b-red-200' : ''
        }`} data-name ="contact" onClick={(e) =>{ setactive(e.currentTarget.dataset.name)
          setMenuOpen(false)}}>
        <Link to={"/contact"}>contact</Link>
        </li></ul>
        {category.length > 0 && (!user||user.role!=="Admin")?  (
  <ul className=" flex flex-row max-md:grid max-md:grid-cols-2 w-[75%] ">
    {category.map((cat) => (
      <li
        key={cat.name} 
        className={` li conditional-li text-center  pt-8  h-16  mx-2 w-32 hover:text-red-200 hover:border-b-4 hover: border-b-red-200 focus:outline-none focus:ring focus:border-blue-300 transition duration-300 ease-in-out ${
          active === cat.name ? 'text-red-200 border-b-4 border-b-red-200' : ''
        }`}
        onClick={(e) => { 
          dispatch(setCategory(e.currentTarget.dataset.name.trim()));
          setactive(e.currentTarget.dataset.name);
          setMenuOpen(false)
        }}
        data-name={cat.name}
      >
        <Link to="/category">{cat.name}</Link>
      </li>
    )
    )}
  </ul>
):(
  <ul className=" flex flex-row max-md:grid max-md:grid-cols-2 w-[75%] ">
  
        <li
        className={`  text-center  max-sm:mr-6   pt-8  h-16  mx-2 w-32 hover:text-fuchsia-600 hover:border-b-4 hover: border-b-fuchsia-600 focus:outline-none focus:ring focus:border-blue-300 transition duration-300 ease-in-out ${
          active === "post-product" ? 'text-fuchsia-600 border-b-4 border-b-fuchsia-600' : ''
        }`}
        onClick={(e) => { 
          
          setactive(e.currentTarget.dataset.name);
          setMenuOpen(false)
        }}
        data-name="post-product"
      >
        <Link to="/post-product">post-product</Link>
      </li>
    
      <li
        className={`  text-center  max-sm:ml-6   pt-8 max-sm:mr-4  h-16  mx-2 w-32 hover:text-fuchsia-600 hover:border-b-4 hover: border-b-fuchsia-600 focus:outline-none focus:ring focus:border-blue-300 transition duration-300 ease-in-out ${
          active === "post-category" ? 'text-fuchsia-600 border-b-4 border-b-fuchsia-600' : ''
        }`}
        onClick={(e) => { 
          
          setactive(e.currentTarget.dataset.name);
          setMenuOpen(false)
        }}
        data-name="post-category"
      >
        <Link to="/post-category">post-category</Link>
      </li>
    
      <li
        className={`  text-center max-sm:mr-8  pt-8  h-16  mx-2 w-32 hover:text-fuchsia-600 hover:border-b-4 hover: border-b-fuchsia-600 focus:outline-none focus:ring focus:border-blue-300 transition duration-300 ease-in-out ${
          active === "update-product" ? 'text-fuchsia-600 border-b-4 border-b-fuchsia-600' : ''
        }`}
        onClick={(e) => { 
          
          setactive(e.currentTarget.dataset.name);
          setMenuOpen(false)
        }}
        data-name="update-product"
      >
        <Link to="/update-product">update-product</Link>
      </li>

      <li
        className={`  text-center  max-sm:ml-8   pt-8  h-16  mx-2 w-32 hover:text-fuchsia-600 hover:border-b-4 hover: border-b-fuchsia-600 focus:outline-none focus:ring focus:border-blue-300 transition duration-300 ease-in-out ${
          active === "update-category" ? 'text-fuchsia-600 border-b-4 border-b-fuchsia-600' : ''
        }`}
        onClick={(e) => { 
          
          setactive(e.currentTarget.dataset.name);
          setMenuOpen(false)
        }}
        data-name="update-category"
      >
        <Link to="/update-category">update-category</Link>
      </li>
  </ul>
)}
        
      
    </div>





{/* in the max md    */}




    <div className={`  header2 md:hidden ${menuOpen===true? ` flex-col align-top justify-start ` : ` max-md:hidden`}  fixed top-0 right-0 w-[250px] flex  text-black bg-white  h-screen z-50`} >

    <img src='/close.png' alt="" className=" w-8 ml-8 mt-8 rounded-full hover:bg-gray-50   " onClick={toggleMenu}>
      </img >
    
<ul className="  flex-col   ">
  <li data-name ="home" onClick={(e) => {setactive(e.currentTarget.dataset.name)
   setMenuOpen(false)}} className={`  hover:bg-gray-50 ml-8 mt-8 h-8 pl-1 pt-1  w-full  ${
    active === 'home' ? ' bg-gray-50' : ``
  }`}
>
<Link to={"/"}>Home</Link>
  </li>
  <li className={`  hover:bg-gray-50 ml-8 mt-8 h-8 pl-1 pt-1  w-full  ${
    active === 'contact' ? ' bg-gray-50' : ``
  }`} data-name ="contact" onClick={(e) =>{ setactive(e.currentTarget.dataset.name)
    setMenuOpen(false)}}>
  <Link to={"/contact"}>contact</Link>
  </li></ul>
  {category.length > 0 && (!user||user.role!=="Admin")?  (
<ul className="  flex-col    ">
{category.map((cat) => (
<li
  key={cat.name} 
  className={` li conditional-li hover:bg-gray-50 ml-8 mt-8 h-8 pl-1 pt-1  w-full ${
    active === cat.name ? 'bg-gray-50 ' : ''
  }`}
  onClick={(e) => { 
    dispatch(setCategory(e.currentTarget.dataset.name.trim()));
    setactive(e.currentTarget.dataset.name);
    setMenuOpen(false)
  }}
  data-name={cat.name}
>
  <Link to="/category">{cat.name}</Link>
</li>
)
)}
</ul>
):(
<ul className="  flex-col  ">

  <li
  className={`  li conditional-li hover:bg-gray-50 ml-8 mt-8 h-8 pl-1 pt-1  w-full ${
    active === "post-product" ? 'bg-gray-50 ' : ''
  }`}
  onClick={(e) => { 
    
    setactive(e.currentTarget.dataset.name);
    setMenuOpen(false)
  }}
  data-name="post-product"
>
  <Link to="/post-product">post-product</Link>
</li>

<li
  className={` li conditional-li hover:bg-gray-50 ml-8 mt-8 h-8 pl-1 pt-1  w-full${
    active === "post-category" ? ' bg-gray-50' : ''
  }`}
  onClick={(e) => { 
    
    setactive(e.currentTarget.dataset.name);
    setMenuOpen(false)
  }}
  data-name="post-category"
>
  <Link to="/post-category">post-category</Link>
</li>

<li
  className={`  li conditional-li hover:bg-gray-50 ml-8 mt-8 h-8 pl-1 pt-1  w-full ${
    active === "update-product" ? ' bg-gray-50' : ''
  }`}
  onClick={(e) => { 
    
    setactive(e.currentTarget.dataset.name);
    setMenuOpen(false)
  }}
  data-name="update-product"
>
  <Link to="/update-product">update-product</Link>
</li>

<li
  className={`  li conditional-li hover:bg-gray-50 ml-8 mt-8 h-8 pl-1 pt-1  w-full ${
    active === "update-category" ? ' bg-gray-50' : ''
  }`}
  onClick={(e) => { 
    
    setactive(e.currentTarget.dataset.name);
    setMenuOpen(false)
  }}
  data-name="update-category"
>
  <Link to="/update-category">update-category</Link>
</li>
</ul>
)}
  

</div>
  
</header>
    )
}
export default Header