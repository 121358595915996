
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import url from "../../config"

import { useNavigate } from "react-router-dom"
import { cartActions } from "../../store/cart"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const Category=()=>{
    const [product,setproduct]=useState([])
   
    const [Subcategory,setSubcategory]=useState([])
   
    
    const cat=useSelector((state)=>
    state.category.category
)
    const dispatch=useDispatch()
    const handelADD=(pro,event)=>{
      event.stopPropagation();
      dispatch(cartActions.addToCart(pro))
      history("/cart")
    }
 gsap.fromTo(".subb",{rotate:0,
      x:-1510},{scrollTrigger:".subb",x:0,duration:2 ,rotate:720,stagger:.3,ease:"power4"})
      
    useEffect(()=>{
      window.scrollTo(0,0)
     
      },[cat])

useEffect(() => {
    const fetchData = async () => {
      gsap.fromTo(".gorie",{y:"200%"},{y:0,duration:1.4})
      gsap.fromTo(".gorie p",{x:"-400%"},{x:0,duration:1.1,delay:1})
        try {
         
          const res = await axios.get(`${url}/categoryproduct`, { params: { category: cat} });
          setproduct(res.data.product);
          const response = await axios.get(`${url}/subcategory`, { params: { parentCategory:cat} });
          setSubcategory(response.data.category);
        
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    

    fetchData(); 
    
  }, [ cat, dispatch]); 

const [active,setactive]=useState("")

const handelsub=async (name)=>{
  setactive(name)
  console.log(name)
  const res= await axios.get(`${url}/similairproduct`,{params:{subcategory:name}})
  console.log(res.data.product)
  setproduct(res.data.product)

}
const history=useNavigate()
const handel=(idd)=>{
  history(`/product/${idd}`)
window.location.reload()
  

}
    return(
        <div>
 
       
        
 <div className=" gorie z-[-1]  bg-gray-900 h-[450px]  text-white  w-full flex items-center justify-center ">
 <p className=" text-4xl my-8 font-serif relative  max-md:my-4 mx-auto"> categorie:{cat}</p>
 
</div>
<div className=" mt-10 ml-6 grid   grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 ">
  <p className="  text-fuchsia-600 text-2xl font-serif ml-10 pb-0 pt-4 border-orange-400 border-b-8 w-48 "> sous categories : </p> 
 
{Subcategory && Subcategory.map((sub)=>(
          
            <div className={` subb bg-gray-300 h-12 ml-8 my-4 rounded-xl w-52 p-2 font-mono hover:bg-gray-900 text-white cursor-pointer ${active===sub.name?`  text-white bg-gray-900`:``} `} onClick={()=>handelsub(sub.name)}>
              <p>{sub.name}</p> 
            </div>
          ))
        }
        
        </div>
        <div className=" similair w-full h-auto  grid grid-cols-4  max-md:grid-cols-3 ">
             
             {product.length>0? product.map((pro)=>(
             <div className=" relative mr-4 w-auto image-container   " onClick={()=>handel(pro._id)}>
             <img  src={pro.imageUrl}  className=" hover:cursor-pointer  h-[35%] mt-8 relative w-[80%] mx-auto" alt=""  loading="lazy"></img>
             <p  className=" hover:text-red-200 hover:cursor-pointer ml-4 mt-9  font-bold  ">{pro.name} </p>
             <div className={` ml-6 text-red-800 ${pro.promotion ? 'line-through ' : 'hidden mt-4'}`}>{pro.price} DA</div>
<div className={`  text-2xl ml-6 mb-20 ${pro.promotion ? '' : ' mt-6'} text-red-600`}>{pro.promotion?pro.promotion:pro.price} DA</div>
<div className="font-bold border-2 text-center  rounded-lg my-8 border-pink-500 bg-pink-500 hover:bg-black text-white py-2 cursor-pointer transition duration-300 ease-in-out" 
   onClick={(event) => // Prevent the default behavior of the link
        handelADD(pro,event)
      }>Ajouter au panier</div>
             
                     </div>
             )):(
                 <> <p>no product found</p></>
             )
             
             }
                     </div>

        </div>
      
    )
} 
export default Category