

const islocal=Boolean(
    window.location.hostname==="localhost"||
    window.location.hostname==="[::1]"||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)
const url=islocal?"http://localhost:5000":"https://api.parapharm-mimosai.com"

export default url