
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import url from "../../config"

import { useNavigate } from "react-router-dom"
import { cartActions } from "../../store/cart"
import gsap from "gsap"



const Recherche=()=>{
    const [product,setproduct]=useState([])
    
    
   
    const dispatch=useDispatch()
const recherche=useSelector((state)=>
    state.recherche.recherche
)
const handelADD=(pro,event)=>{
  event.stopPropagation();
  dispatch(cartActions.addToCart(pro))
  history("/cart")
}

useEffect(() => {
    const fetchData = async () => {
      window.scroll(0,0)
      gsap.fromTo(".gorie",{y:"200%"},{y:0,duration:1.4})
      gsap.fromTo(".gorie p",{x:"-400%"},{x:0,duration:1.1,delay:1})
      if (recherche  ) {
        try { 
            
          const res = await axios.get(`${url}`,{params:{name:recherche}});
          console.log(res.data.product)
          setproduct(res.data.product);
          
         
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } 
    };

    fetchData(); 
    
  }, [recherche, dispatch]); 
const history=useNavigate()
  const handel=(idd)=>{
    history(`/product/${idd}`)
  window.location.reload()
    
  
  }


    return(
        <div>
            <div className=" gorie bg-gray-900 h-[100vh]  text-white  w-full flex items-center justify-center">
              <p className=" text-4xl my-8 font-serif relative   max-md:my-4 mx-auto">  resultat de la recherche de:{recherche}</p>
              
        </div>
        <div className=" similair w-full h-auto  grid grid-cols-4 max-md:grid-cols-3 ">
{product.length>0?product.map((pro)=>(
             <div className=" mr-4 relative w-auto image-container   " onClick={()=>handel(pro._id)}>
             <img  src={pro.imageUrl}  className=" hover:cursor-pointer  h-[35%] mt-8 relative w-[80%] mx-auto" alt=""  loading="lazy"></img>
             <p  className=" hover:text-red-200 hover:cursor-pointer ml-4 mt-9  font-bold  ">{pro.name}  </p>
             <div className={` ml-6 text-red-800 ${pro.promotion ? 'line-through ' : 'hidden mt-4'}`}>{pro.price} DA</div>
<div className={`  text-2xl ml-6 mb-20 ${pro.promotion ? '' : ' mt-6'} text-red-600`}>{pro.promotion?pro.promotion:pro.price} DA</div>
             
<div className="font-bold border-2 text-center  rounded-lg my-8 border-pink-500 bg-pink-500 hover:bg-black text-white py-2 cursor-pointer transition duration-300 ease-in-out" 
   onClick={(event) => // Prevent the default behavior of the link
        handelADD(pro,event)
      }>Ajouter au panier</div>

                     </div>
             ))


:(
    <div className=" font-bold"> product not found</div>
)

}
        </div>

        </div>
        
    )
} 
export default Recherche