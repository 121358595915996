import React, { useEffect, useState } from "react";
import axios from "axios";
import url from "../../config";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Updateproduct = () => {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading status
  const user = useSelector((state) => state.user.user);
  const history = useNavigate();

  useEffect(() => {
    if (!user || user.role !== "Admin") history("/");
  }, [user, history]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await axios.get(`${url}/product`);
        setProduct(res.data.product);
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error("Error fetching products: ", error);
        toast.error("Failed to fetch products");
      }
    };

    fetchProduct();
  }, []);

  const handelchange = (id, field, value) => {
    setProduct((prev) =>
      prev.map((pro) => (pro._id === id ? { ...pro, [field]: value } : pro))
    );
  };

  const handleClick = async (id, e) => {
    e.preventDefault();
    try {
      const update = product.find((pro) => pro._id === id);
      const res = await axios.patch(`${url}/product/${id}`, update, {
        withCredentials: true,
      });
      toast.success(res.data.message);
    } catch (error) {
      console.error("Error updating product: ", error);
      toast.error(error.response.data.message);
    }
  };
  const [editingMode,seteditingMode]=useState(null)

  const handleDelete = async (id, e) => {
    e.preventDefault();
    try {
      const res = await axios.delete(`${url}/product/${id}`, {
        withCredentials: true,
      });
      setProduct((pros) => pros.filter((pro) => pro._id !== id));
      toast.success(res.data.message);
    } catch (error) {
      console.error("Error deleting product: ", error);
      toast.error(error.response.data.message);
    }
  };

  const [input, setInput] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (input ===""||input===" ") { 
      
      const fetchProduct = async () => {
        try {
          const res = await axios.get(`${url}/product`);
          setProduct(res.data.product);
          setLoading(false); // Set loading to false after fetching data
        } catch (error) {
          console.error("Error fetching products: ", error);
          toast.error("Failed to fetch products");
        }
      };
  
      fetchProduct();
    
}
    const filteredProducts = product.filter((pro) =>
      pro.name.toLowerCase().includes(input.toLowerCase())
    );
    setProduct(filteredProducts);
    setInput("");
  };
  

  return (
    <>
      <form
        className="w-60 h-40 border-8 my-4 mx-auto"
        onSubmit={handleSubmit}
      >
        <label className="ml-2">Name of product you search:</label>
        <input
          placeholder="Enter the name"
          className="pl-2 hover:border-black my-6 mx-4 border-4 border-blue-400 relative w-[80%]"
          value={input}
          type="text"
          onChange={(e) => setInput(e.target.value)}
        />
        <button
          className="border-4 border-blue-400 w-20 relative bottom-[0px] ml-16 bg-blue-400 text-white hover:bg-black"
          type="submit"
          onClick={handleSubmit}
        >
          Search
        </button>
      </form>

      {loading ? ( // Display loading message if data is being fetched
        <p>Loading...</p>
      ) : (
        product.map((pro) => (
          <form
            className="grid rounded-lg grid-cols-1 mt-5 w-[80%] h-auto mx-auto border-[6px] border-purple-800"
            key={pro._id}
          >
              <label className="w-[50%] mx-auto">name :</label>
            <input
              className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"
              disabled={editingMode !== pro._id ? true : false}
              type="text"
              value={pro.name}
              name="name"
              onChange={(e) => handelchange(pro._id, e.target.name, e.target.value)}
            />
            <label className="w-[50%] mx-auto">description :</label>
            <input
              type="text"
              disabled={editingMode !== pro._id ? true : false}
              className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"
              value={pro.description}
              name="description"
              onChange={(e) => handelchange(pro._id, e.target.name, e.target.value)}
            />
            <label className="w-[50%] mx-auto">conseils d'utilisation :</label>
            <input
              type="text"
              disabled={editingMode !== pro._id ? true : false}
              className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"
              value={pro.conseils}
              name="conseils"
              onChange={(e) => handelchange(pro._id, e.target.name, e.target.value)}
            />
            <label className="w-[50%] mx-auto">price :</label>
            <input
              className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"
              disabled={editingMode !== pro._id ? true : false}
              type="number"
              value={pro.price}
              name="price"
              onChange={(e) => handelchange(pro._id, e.target.name, e.target.value)}
            />
            <label className="w-[50%] mx-auto">quantity :</label>
            <input
              type="number"
              disabled={editingMode !== pro._id ? true : false}
              className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"
              value={pro.quantity}
              name="quantity"
              onChange={(e) => handelchange(pro._id, e.target.name, e.target.value)}
            />
            <label className="w-[50%] mx-auto">promotion :</label>
            <input
              className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"
              disabled={editingMode !== pro._id ? true : false}
              type="number"
              value={pro.promotion}
              name="promotion"
              onChange={(e) => handelchange(pro._id, e.target.name, e.target.value)}
            />
            <label className="w-[50%] mx-auto">category :</label>
            <input
              type="text"
              disabled={editingMode !== pro._id ? true : false}
              className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"
              value={pro.category}
              name="category"
              onChange={(e) => handelchange(pro._id, e.target.name, e.target.value)}
            />
            <label className="w-[50%] mx-auto">subcategory :</label>
            <input
              className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"
              disabled={editingMode !== pro._id ? true : false}
              type="text"
              value={pro.subcategory}
              name="subcategory"
              onChange={(e) => handelchange(pro._id, e.target.name, e.target.value)}
            />
            <label className="w-[50%] mx-auto">image Url :</label>
            <input
              type="text"
              disabled={editingMode !== pro._id ? true : false}
              className=" w-[50%] border-4  my-2 border-cyan-400 mx-auto p-2"
              value={pro.imageUrl}
              name="imageUrl"
              onChange={(e) => handelchange(pro._id, e.target.name, e.target.value)}
            />
            <button
              className="my-8 w-[20%] max-md:w-[40%] mx-auto border-4 h-9 text-white bg-black border-cyan-200 hover:bg-green-500"
              onClick={(e) => handleClick(pro._id, e)}
            >
              Update
            </button>
            <button
              className="my-8 w-[20%] max-md:w-[40%] mx-auto border-4 h-9 text-white bg-red-700 border-cyan-200 hover:bg-red-900"
              onClick={(e) => handleDelete(pro._id, e)}
            >
              Delete
            </button>
            <label className="w-[50%] mx-auto mb-0">enable editing :</label>
            <input
              type="checkbox"
              className="w-4 mt-[-15px] mb-2  border-cyan-400 max-md:ml-[70%]  ml-[37%]"
              onChange={() => {
                seteditingMode(pro._id);
              }}
            />
          </form>
        ))
      )}

      <hr className="w-full" />
    </>
  );
};

export default Updateproduct;
