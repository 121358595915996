import axios from "axios"
import { useEffect, useState } from "react"
import {  useNavigate, useParams } from "react-router-dom"
import url from "../../config"
import { useDispatch } from "react-redux"
import { cartActions } from "../../store/cart"
import gsap from "gsap"



const ProductDetails=()=>{
  
const {id}=useParams()
const [produitSimilair,setproduitSimilair]=useState([])
const [product,setproduct]=useState([])
useEffect(()=>{
 
  gsap.fromTo(".image1",{
  x:"-200%"},{scrollTrigger:".image1",x:0,duration:2 ,stagger:.3,ease:"power4"})
  
  },[])
useEffect(()=>{
    const fetchData = async () => {
      window.scrollTo(0, 0);

        try {
          const resss=await axios.get(`${url}/product/${id}`)
          const pro=resss.data.product
          
          const response = await axios.get(`${url}/product/${id}`);
          setproduct(response.data.product);
          const res= await axios.get(`${url}/similairproduct`,{params:{subcategory:pro.subcategory}})
          let similarProducts = res.data.product;

          // Filter out the main product from the similar products
          similarProducts = similarProducts.filter(
            (similarProduct) => similarProduct._id !== id
          );
          
          setproduitSimilair(similarProducts);
        } catch (error) {
          console.error('Error fetching product:', error);
          // You might want to set an error state or show an error message to the user
        }
      };
    
      fetchData();}
,[id]) 
const history=useNavigate()

const handel=(idd)=>{
  history(`/product/${idd}`)
window.location.reload()
  

}
const dispatch=useDispatch()
const handelADD=(pro,e)=>{
  
  dispatch(cartActions.addToCart(pro))
  history("/cart")
}

    return(
        <>
        {product && (
            <div className=" w-full flex max-sm:grid max-md:grid-cols-1 ">
              <div className="relative w-[30%] max-md:mx-auto  max-md:w-[70%]">
                  <img src={`${product.imageUrl}?${new Date().getTime()}`} className=" image1 relative w-full h-[70%] transition-transform transform-gpu hover:scale-110 my-10 cursor-pointer  " alt=""></img>
                  </div>
                  <div className=" relative w-[70%] max-md:w-[80%] grid grid-cols-1 mt-12 max-md:mx-auto">
<p className=" font-serif  text-2xl relative ">{product.name} </p>
<hr className=" border-4 my-4"></hr>

<p className=" text-2xl font-bold  text-green-400 ">{product.price} DA</p> <hr className=" border-4 my-4"></hr>
<p className=" font-serif text-xl relative     ">{product.description}</p><hr className=" border-4 my-4"></hr>
<p className="font-serif text-2xl relative my-4"> conseils d'utilisation :</p>
<p className=" bg-gray-300 relative  h-[300px] overflow-scroll">{product.conseils}</p>
<div className="font-bold border-2 text-center  rounded-lg my-8 border-pink-500 bg-pink-500 hover:bg-black text-white py-2 cursor-pointer transition duration-300 ease-in-out" onClick={(event) => {
        // Prevent the default behavior of the link
        handelADD(product);
      }}>Ajouter au panier</div>
                </div></div>

        )}
        <p className=" font-bold my-6 ml-4  text-red-600 text-2xl">Produit similair :</p>
        <hr className=" border-8"></hr>
        <div className=" similair  grid grid-cols-4 max-md:grid-cols-3 ">
           
        { produitSimilair.length>0 &&

produitSimilair.map((produit)=>(
  
<div className=" relative  image-container mr-4  " onClick={()=>handel(produit._id)}>
<img  src={produit.imageUrl}  className=" hover:cursor-pointer h-[40%]   mt-8 relative w-[80%] mx-auto" alt=""  loading="lazy"></img>
<p  className=" hover:text-green-400 hover:cursor-pointer ml-4 mt-9  font-bold  ">{produit.name}  kc cmfkvmdo vdjvodmv vkfmvmvk kiknlkh ljhoiuhkj</p>
<div className={` ml-6 text-red-800 ${produit.promotion ? 'line-through ' : 'hidden mt-4'}`}>{produit.price} DA</div>
<div className={`  text-2xl ml-6 mb-20 ${produit.promotion ? '' : ' mt-6'} text-red-600`}>{produit.promotion?produit.promotion:produit.price} DA</div>
<div className=" font-bold border-2 text-center  rounded-lg my-8 border-pink-500 bg-pink-500 hover:bg-black text-white py-2 cursor-pointer transition duration-300 ease-in-out mb-36"  onClick={(e) =>handelADD(produit,e)}>Ajouter au panier</div>

        </div>
))

        }</div>
        </>
    )
}
export default ProductDetails