
import { BrowserRouter, Route,  Routes } from 'react-router-dom';
import './App.css';
import Header from './components/layout/header';
import Category from './components/product/category';
import Recherche from './components/product/recherch';
import Footer from './components/layout/footer';
import Home from './components/home/home';
import PostProduct from './components/admin/post-product';
import { Toaster } from 'react-hot-toast';
import PostCategory from './components/admin/post-category';
import UpdateCategory from './components/admin/update-category';
import Updateproduct from './components/admin/update-product';
import Login from './components/auth/login';

import ProductDetails from './components/product/prouctDetails';
import Contact from './components/contact/contact';
import Cart from './components/commande/cart';
import Checkout from './components/commande/checkout';


function App() {
  return (
  <>
  <BrowserRouter>
  <Header/>
  <Routes>
    <Route path='/category' element={<Category/>}/>
    <Route path='/recherche' element={<Recherche/>}/>
    <Route path='/' element={<Home/>}/>
    <Route path='/post-product' element={<PostProduct/>}/>
    <Route path='/post-category' element={<PostCategory/>}/>
    <Route path='/update-category' element={<UpdateCategory/>}/>
    <Route path='/update-product' element={<Updateproduct/>}/>
    <Route path='/login' element={<Login/>}/>
    <Route path='/product/:id' element={<ProductDetails/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/cart' element={<Cart/>}/>
    <Route path='/checkout' element={<Checkout/>}/>
  </Routes>
  <Toaster/>
  <Footer/>
  </BrowserRouter></>
  );
}

export default App;
