import axios from "axios"
import React, { useEffect,  useState } from "react"
import url from "../../config"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"
import { setCategory } from "../../store/category";
import { cartActions } from "../../store/cart";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { setRecherche } from "../../store/recherch";

gsap.registerPlugin(ScrollTrigger)


const Home=(props)=>{
const [newProduct,setnewProduct]=useState([])
const [promotionProduct,setpromotionProduct]=useState([])
const history=useNavigate()
 const dispatch=useDispatch()

 

 useGSAP(()=>{
 
  gsap.from(".slider",{x:"-200%", duration:2, delay:.5})
  gsap.from(".complementss img",{y:"-1300%", duration:2, delay:.2,stagger:0.3})

 })

  
    

const handelcategory=(e)=>{
 
  dispatch(setCategory(e.target.name))
  history("/category")
}

const handelADD=(pro)=>{
  dispatch(cartActions.addToCart(pro))
 
  history('/cart')
}

useEffect(()=>{

 
gsap.to(".h1",{scrollTrigger:{trigger:".h1",
toggleActions:"restart none none none"
}, x:0,duration:2 ,ease:"power3"})

},[])
gsap.to(".nouveaux",{scrollTrigger:{trigger:".nouveaux",
toggleActions:"restart none none none"
},
 x:0,duration:2 ,ease:"power3"})

useEffect(()=>{
const fetch=async()=>{

window.scrollTo(0,0)

gsap.registerPlugin(ScrollTrigger)
gsap.fromTo(".h1",{x:700},{scrollTrigger:".h1", x:0,duration:2 ,ease:"power3"})
gsap.fromTo(".nouveaux",{x:700},{scrollTrigger:".nouveaux", x:0,duration:2 ,ease:"power3"})
    try {
         const res=await axios.get(`${url}/getLatestProduct`)
    setnewProduct(res.data.product)
    const response=await axios.get(`${url}/getPromotionProduct`)
    setpromotionProduct(response.data.product)
    } catch (error) {
        console.log(error)
    }
   
}
fetch()
},[])
const handleclick=async(e)=>{
  e.preventDefault()
  
    dispatch(setRecherche())
    const inputElement = document.getElementById("input");
    if (inputElement) {
      inputElement.value = "";
    }
  history("/recherche")
  
  }
const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const responsivee2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
 


    return(
        <>
        <div className=" bg linen h-[650px] mt-[-85px] w-full    ">
   <div className=" slider bg-white relative w-[70%] max-md:w-[90%] h-[60%] max-sm:h-[50%] lg:h-[80%]  m-auto top-[10%] max-sm:top-[5%]" >
   <Carousel   swipeable={false} 
  draggable={false}
  showDots={false}
  
  responsive={responsivee2}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={2500}
  keyBoardControl={true}
className=" my-20   "
  customTransition={ "transform 1s ease-in-out, opacity 0.5s ease-in-out" }
  transitionDuration={1000}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px">
    
<img className=" im relative w-[70%] h-[50%]   mx-auto  " src="https://media.gettyimages.com/id/1249466095/fr/photo/makeup-supplies-amidst-shiny-glitter.jpg?s=612x612&w=gi&k=20&c=fJdqqJ7LKazkaUzswDOtsgg4jk6utTiUfglzzcVxb20=" alt=""></img>
<img className="im relative w-[70%] h-[50%]  mx-auto  " src="/image1.png" alt=""></img>
<img className="im relative w-[70%]   h-[50%] mx-auto  " src="/image2.png" alt=""></img>
<img className="im relative w-[70%] h-[50%]  mx-auto  " src="https://m.media-amazon.com/images/I/61pn05pv-dL.jpg" alt=""></img>
<img className="im relative w-[70%] h-[50%]   mx-auto  " src="https://img.freepik.com/free-vector/night-cosmetic-series-face-skin-care_88138-97.jpg" alt=""></img>
</Carousel>
   </div>
   <div className=" complementss">
<img onClick={handelcategory} name="complements alimentaires" className=" complement alimentaires  absolute mb-8 right-[0px] w-[15%] top-[400px] max-md:top-[660px]  max-md:w-[30%]  max-md:right-[70%] cursor-pointer opacity-80 hover:opacity-100" src="https://cdn.shortpixel.ai/spai/w_230+q_lossy+ret_img+to_webp/ho-parapharmacie.com/wp-content/uploads/2022/04/complement-image-1.png" alt=""></img>
<img onClick={handelcategory} name="orthopédie"  className=" complement orthopedie absolute right-[0px] mb-8 w-[15%] top-[529px] max-md:top-[660px] max-md:w-[30%] max-md:right-[35%] cursor-pointer opacity-80 hover:opacity-100" src="https://cdn.shortpixel.ai/spai/w_230+q_lossy+ret_img+to_webp/ho-parapharmacie.com/wp-content/uploads/2022/04/orthopedie-store-image-1.png" alt=""></img>
<img onClick={handelcategory} name="maman-bébé" className=" complement maman absolute right-[0px] mb-8 w-[15%] top-[650px] max-md:top-[660px] max-md:w-[30%] max-md:right-[0%] cursor-pointer opacity-80 hover:opacity-100" src="https://cdn.shortpixel.ai/spai/w_230+q_lossy+ret_img+to_webp/ho-parapharmacie.com/wp-content/uploads/2022/04/maman-store-image-1.png" alt=""></img>
      </div>  </div>
        <div className=" mt-8">
        
<h1 className=" h1 text-2xl font-bold paris  text-red-800 my-4 ml-4"> Produit en promotion :</h1>
<hr className="border-red-200 border-8"></hr>
<Carousel   swipeable={false}
  draggable={false}
  showDots={false}
  
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={2300}
  keyBoardControl={true}
className=" my-20  "
  customTransition={ "transform 2s ease-in-out, opacity 0.5s ease-in-out" }
  transitionDuration={1000}
  containerClass="carousel-container"
 // removeArrowOnDeviceType={["tablet", "mobile"]}
  
  deviceType={props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px">
     
  
{promotionProduct && 
    promotionProduct.map((pro)=>(
      <Link to={`/product/${pro._id}`}>
<div  className=" image-container   w-[70%] h-[600px]  hover:border-2 p-1 hover:border-red-300 rounded-lg" >
    <img  src={pro.imageUrl}  className=" cursor-pointer  h-[45%] mt-8 relative w-[80%] mx-auto" alt=""  loading="lazy"></img>
<p  className=" hover:text-red-400 hover:cursor-pointer my-6  font-bold  ">{pro.name}</p>
<div className={` ml-6 text-red-800 ${pro.promotion ? 'line-through ' : 'hidden'}`}>{pro.price} DA</div>
<div className=" mt-1  text-2xl ml-6 mb-20 text-red-600">{pro.promotion?pro.promotion:pro.price} DA</div>

<div className="font-bold border-2 text-center  rounded-lg my-8 border-pink-500 bg-pink-500 hover:bg-black text-white py-2 cursor-pointer transition duration-300 ease-in-out" onClick={(event) => {
  event.preventDefault(); // Prevent the default behavior of the link
  handelADD(pro);
}}>
  Ajouter au panier
</div>
</div></Link>
    ))

}
</Carousel>
   
<h1 className=" nouveaux font-bold mt-20 text-2xl text-red-800  paris mb-4 ml-4"> nouveau produits :</h1>
<hr className=" border-red-100 border-8" ></hr>
<Carousel   swipeable={false}
  draggable={false}
  showDots={false}
  
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={2300}
  keyBoardControl={true}

  customTransition={ "transform 2s ease-in-out, opacity 0.5s ease-in-out" }
  transitionDuration={1000}
  containerClass="carousel-container"
 // removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px">
     
  
{newProduct && 
    newProduct.map((pro)=>(
<Link to={`/product/${pro._id}`}><div  className=" image-container  w-[70%] h-[600px]  hover:border-2 p-1 hover:border-red-300 rounded-lg" >
    <img  src={pro.imageUrl}  className=" cursor-pointer  h-[45%] mt-8 relative w-[80%] mx-auto" alt=""  loading="lazy"></img>
<p  className=" hover:text-red-400 hover:cursor-pointer my-6  font-bold  ">{pro.name}</p>
<div className={` ml-6 text-red-800 ${pro.promotion ? 'line-through ' : 'hidden mt-4'}`}>{pro.price} DA</div>
<div className={`  text-2xl ml-6 mb-20 ${pro.promotion ? '' : ' mt-10'} text-red-600`}>{pro.promotion?pro.promotion:pro.price} DA</div>
<div className="font-bold border-2 text-center  rounded-lg my-8 border-pink-500 bg-pink-500 hover:bg-black text-white py-2 cursor-pointer transition duration-300 ease-in-out" onClick={(event) => {
  event.preventDefault(); // Prevent the default behavior of the link
  handelADD(pro);
}}>
  Ajouter au panier
</div>

</div></Link>
    ))

}
</Carousel>
        </div>
    </>
    )
} 
export default Home