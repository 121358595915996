import {createSlice} from "@reduxjs/toolkit"
const userSlice=createSlice({
    name:"user",
    initialState:{
        user:null,
    },
    reducers:{
        setUser:(state,action)=>{
            state.user=action.payload
        },
        reset: (state) => {
            state.user=null
            
          },
    }
})
export const {setUser,reset}=
userSlice.actions;
export default userSlice.reducer;